import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

type CheckIconProps = {
  className?: string;
};

const CheckIcon: FC<CheckIconProps> = ({ className }) => {
  return (
    <svg
      className={clsx(className)}
      xmlns="http://www.w3.org/2000/svg"
      width={111}
      height={111}
      viewBox="0 0 109.24 111.01"
    >
      <g>
        <g>
          <path
            fill="currentColor"
            d="M109.47,45.27,45.3,109.49a5.18,5.18,0,0,1-7.35,0L1.53,73a5.2,5.2,0,0,1,0-7.37l8.61-8.6a5.17,5.17,0,0,1,7.34,0L41.64,81.24,93.56,29.32a5.18,5.18,0,0,1,7.35,0l8.58,8.58A5.22,5.22,0,0,1,109.47,45.27ZM14.9,36.56a3.49,3.49,0,0,1,0-4.9l9.83-9.82a3.43,3.43,0,0,1,4.9,0l12,12L74.48,1a3.45,3.45,0,0,1,4.9,0l9.8,9.82a3.49,3.49,0,0,1,0,4.9L44.09,60.88a3.43,3.43,0,0,1-4.9,0Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default CheckIcon;
