import { EXPERIMENTS } from '@/abTestDefinitions';
import AbstractCardFlipSection from '@/components/home/AbstractCardFlipSection';
import step1 from '@/images/home/screen2/screen_2_01.js.svg';
import step2 from '@/images/home/screen2/screen_2_02.svg';
import step3 from '@/images/home/screen2/screen_2_03.svg';
import step4 from '@/images/home/screen2/screen_2_04.svg';
import step5 from '@/images/home/screen2/screen_2_05.svg';
import step6 from '@/images/home/screen2/screen_2_06.svg';
import step7 from '@/images/home/screen2/screen_2_07.svg';
import { useSafeExperiment } from '@/lib/hooks/useSafeExperiment';
import * as React from 'react';
import { FC, useMemo } from 'react';

type NextLevelSectionProps = {};

const allItems = [
  {
    image: (
      <object className="flex-1" type="image/svg+xml" data={step1}>
        screen_2_01
      </object>
    ),
    header:
      'Stop editing the quote every time customers want alternative products',
    body: 'Give them the power to choose their favorite product on their own',
  },
  {
    image: null,
    imageSrc: step2,
    header: "Don't switch important tasks for trivial quantity changes",
    body: 'Customers can now modify the quantity on their own',
  },
  {
    image: null,
    imageSrc: step3,
    header:
      "Don't make the client feel that you force them to buy unnecessary extras",
    body: 'Now you can let them view, understand, and choose accessories themselves',
  },
  {
    image: null,
    imageSrc: step4,
    header: 'Stop chasing deal-related conversations in multiple places',
    body: 'Direct clients toward an organized chat in his deal room',
  },
  {
    image: null,
    imageSrc: step5,
    header: 'Part of your grind is remembering every single step of the deal',
    body: 'Use your energy elsewhere. Every change is now recorded in a change-log',
  },
  {
    image: null,
    imageSrc: step6,
    header: "Don't run around with a pen to get a deal signed",
    body: 'Customers can commit to quotes by accepting them online',
  },
  {
    image: null,
    imageSrc: step7,
    header: "Payments don't come easy",
    body: 'Enable buyers to make a deposit or pay the quote all together',
  },
];

const NextLevelSection: FC<NextLevelSectionProps> = () => {
  const { selectVariant, activeVariant } = useSafeExperiment(
    EXPERIMENTS['211031 - Homepage Length']
  );

  const items = useMemo(
    () =>
      selectVariant({
        [EXPERIMENTS['211031 - Homepage Length'].variants[0]]: allItems,
        [EXPERIMENTS['211031 - Homepage Length'].variants[1]]: [
          allItems[0],
          allItems[2],
          allItems[5],
          allItems[6],
        ],
      }),
    [activeVariant]
  );

  return (
    <AbstractCardFlipSection
      id="nextLevel"
      title="Next level of client experience & deal facilitation"
      rtl
      items={items}
    />
  );
};

export default NextLevelSection;
