import EyesIcon from '@/images/home/EyesIcon';
import QuoteIcon from '@/images/home/QuoteIcon';
import SolutionIcon from '@/images/home/SolutionIcon';
import { useIsMobile } from '@/lib';
import * as React from 'react';
import { FC, ReactNode, useState } from 'react';
import { animated, useSpring } from 'react-spring';

type EyesSectionProps = {};

const EyesSection: FC<EyesSectionProps> = () => {
  const isMobile = useIsMobile();
  const [flipDirection, setFlipDirection] = useState(false);
  const { desktopEye, mobileEye } = useSpring({
    from: {
      desktopEye: -13,
      mobileEye: -3,
    },
    to: {
      desktopEye: 13,
      mobileEye: 15,
    },
    reset: true,
    reverse: flipDirection,
    onRest: () => setFlipDirection(!flipDirection),
  });

  function renderItem(icon: ReactNode, text: string) {
    return (
      <div className="flex flex-col items-center w-60">
        <div className="flex flex-col justify-center h-44 mb-9">{icon}</div>
        <div className="text-lg h-36 text-center">{text}</div>
      </div>
    );
  }

  return (
    <section className="min-h-screen scroll-snap-start w-screen flex flex-col items-center bg-primary text-white pt-12 pb-20 relative z-10">
      <div className="relative flex flex-col w-full flex-1 max-w-5xl justify-around">
        <div className="font-semibold text-3xl text-center max-w-xl mb-20 self-center">
          Why are you going through that process?
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between">
          {renderItem(
            <SolutionIcon />,
            "You've already built a solution for the customer in your mind"
          )}
          {renderItem(
            <EyesIcon
              leftEye={
                <>
                  <animated.path
                    style={{
                      transform: isMobile
                        ? mobileEye.to((e) => `translateY(${e}px)`)
                        : desktopEye.to((e) => `translateX(${e}px)`),
                    }}
                    className="cls-2"
                    d="M52.19,76.44a8.32,8.32,0,1,1,3-6.37A8.31,8.31,0,0,1,52.19,76.44Z"
                  />
                </>
              }
              rightEye={
                <>
                  <animated.path
                    style={{
                      transform: isMobile
                        ? mobileEye.to((e) => `translateY(${e}px)`)
                        : desktopEye.to((e) => `translateX(${e}px)`),
                    }}
                    className="cls-2"
                    d="M173.75,76.44a8.32,8.32,0,1,1,3-6.37A8.31,8.31,0,0,1,173.75,76.44Z"
                  />
                </>
              }
            />,
            'You need to make sure that the customer understands it'
          )}
          {renderItem(
            <QuoteIcon />,
            'A quote is the best way of showcasing your solution without  confusing the customer'
          )}
        </div>
      </div>
    </section>
  );
};

export default EyesSection;
