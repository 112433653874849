import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

type TermsIconProps = {
  className?: string;
};

const TermsIcon: FC<TermsIconProps> = ({ className }) => {
  return (
    <svg
      className={clsx(className)}
      xmlns="http://www.w3.org/2000/svg"
      width={111}
      height={111}
      viewBox="0 0 109.24 111.01"
    >
      <g>
        <g>
          <path
            fill="currentColor"
            d="M104.06,27.74c0,48-29.46,74.71-48,82.45a10.46,10.46,0,0,1-8,0C24.82,100.52,0,70.77,0,27.74a10.38,10.38,0,0,1,6.42-9.6L48,.79a10.52,10.52,0,0,1,8,0L97.67,18.14A10.39,10.39,0,0,1,104.06,27.74ZM90.17,30,52,14.15l0,82.6C72.37,86.64,89.45,62.86,90.17,30Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default TermsIcon;
