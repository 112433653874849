import CheckboxInput from '@/components/form/CheckboxInput';
import TextInput from '@/components/form/TextInput';
import { getCookie } from '@/lib';
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
  gql,
  useMutation,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useFormik } from 'formik';
import { Link } from 'gatsby';
import * as React from 'react';
import { FC, useState } from 'react';
import { useBoolean } from 'react-use';
import * as Yup from 'yup';

const httpLink = createHttpLink({
  uri: process.env.APP_GRAPHQL_LINK,
});

const authLink = setContext((_, { headers }) => {
  const fbc = getCookie('_fbc');
  const fbp = getCookie('_fbp');
  return {
    headers: {
      ...headers,
      ...(fbc ? { 'x-fbc': fbc } : {}),
      ...(fbp ? { 'x-fbp': fbp } : {}),
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const withClient = (Component) => (props) =>
  (
    <ApolloProvider client={client}>
      <Component {...props} />
    </ApolloProvider>
  );

type JoinBetaSectionProps = {};

const JoinBetaSection: FC<JoinBetaSectionProps> = () => {
  const [success, setSuccess] = useBoolean(false);
  const [error, setError] = useState<string>();

  const [signup] = useMutation(gql`
    mutation auth_sendSignupLink($email: String!, $name: String!) {
      sendSignupLink(email: $email, name: $name, origin: "${process.env.APP_LINK}") {
        email
      }
    } 
  `);

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      toc: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Please provide your email address'),
      name: Yup.string().required('Please provide your name'),
      toc: Yup.boolean()
        .isTrue('You must accept T&C')
        .required('You must accept T&C'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSuccess(false);
      setError(undefined);
      setSubmitting(true);

      try {
        await signup({
          variables: {
            email: values.email,
            name: values.name,
          },
        });

        setSuccess(true);
      } catch (ex) {
        setError(ex?.toString());
      }

      setSubmitting(false);
    },
  });

  return (
    <section
      id="join-beta"
      className="min-h-screen lg:min-h-4/5screen w-screen flex justify-center items-center px-4 py-10"
    >
      <form
        onSubmit={formik.handleSubmit}
        className="relative flex flex-col width-full max-w-5xl text-lg"
      >
        <div
          style={{ width: 'fit-content' }}
          className="font-semibold text-white bg-yellow rounded-md text-xl text-center self-center py-2 px-4 w-fit mb-8 mx-4 lg:mx-20"
        >
          Free Open Beta
        </div>
        <h2 className="font-semibold text-4xl lg:text-6xl text-center mb-6">
          Join the Quoting Revolution!
        </h2>
        <div className="font-light text-black rounded-lg lg:p-12">
          <TextInput
            formik={formik}
            name="email"
            label="Email"
            placeholder="eg: melon@spaceY.com"
            className="mb-4"
          />
          <TextInput
            formik={formik}
            name="name"
            label="Name"
            placeholder="eg: Melon Musk"
            className="mb-4"
          />
          <CheckboxInput formik={formik} name="toc">
            I accept the
            <Link to="/terms-and-conditions/" className="ml-1 underline">
              Terms & Conditions
            </Link>
          </CheckboxInput>
          <div className="flex flex-col items-center justify-center mt-8">
            {error && (
              <div id="OptInError" className="text-error mb-6">
                {error}
              </div>
            )}

            {success ? (
              <div id="OptInSuccess" className="text-primary">
                We&apos;ve sent a email to{' '}
                <strong>{formik.values.email}</strong>. Follow the instructions
                in the email to activate your account!
              </div>
            ) : (
              <button
                type="submit"
                className="flex items-center justify-center px-6 py-4 border border-transparent rounded-md shadow-sm text-xl font-medium text-white bg-primary hover:bg-primary-200"
                disabled={formik.isSubmitting}
              >
                Let&apos;s get down to business!
                {formik.isSubmitting && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="animate-spin ml-4 h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                )}
              </button>
            )}
          </div>
        </div>
      </form>
    </section>
  );
};

export default withClient(JoinBetaSection);
