import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

type SendIconProps = {
  className?: string;
};

const SendIcon: FC<SendIconProps> = ({ className }) => {
  return (
    <svg
      className={clsx(className)}
      xmlns="http://www.w3.org/2000/svg"
      width={111}
      height={111}
      viewBox="0 0 109.24 111.01"
    >
      <g>
        <g>
          <path
            fill="currentColor"
            d="M110.91,6.08,95.3,99.73a5.21,5.21,0,0,1-7.15,4l-27-11.32L47.37,109.12a5.21,5.21,0,0,1-9.22-3.43V88.24L90.38,24.61a1.3,1.3,0,0,0-1.86-1.8L26.23,77.71,3.18,68a5.21,5.21,0,0,1-.47-9.37L103.19.7A5.21,5.21,0,0,1,110.91,6.08Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default SendIcon;
