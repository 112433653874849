import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { FC } from 'react';

type CompetitionSectionProps = {};

const CompetitionSection: FC<CompetitionSectionProps> = () => {
  const { competitionImage } = useStaticQuery(graphql`
    query CompetitionSectionQuery {
      competitionImage: file(relativePath: { eq: "home/competition.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 1600
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  return (
    <section className="min-h-screen w-screen flex justify-center items-center px-4 py-10">
      <div className="relative flex flex-col width-full max-w-5xl">
        <div className="flex flex-col-reverse lg:flex-row items-center">
          <GatsbyImage
            image={getImage(competitionImage)}
            alt="competition dreaming of the opti advantages"
            className="flex-3 lg:mr-14"
          />

          <div className="flex-2">
            <div className="font-semibold text-4xl mb-11">
              While you take off, your competitors will:
            </div>
            <div className="text-gray-400 text-xl">
              <p className="mb-8">
                Make customer wait for days on end for quotes and changes.
              </p>
              <p className="mb-8">
                Confuse them with messy, non-descriptive quotes.
              </p>
              <p className="mb-8">
                Make them feel that they have no choice or real understanding of
                their own deal.
              </p>
              <p className="mb-8">
                Assume and hedge bets about the customers interaction with the
                quote.
              </p>
              <p className="mb-8">Lose and delay deals one after another.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompetitionSection;
