import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

type SolutionIconProps = {
  className?: string;
};

const SolutionIcon: FC<SolutionIconProps> = ({ className }) => {
  return (
    <svg
      className={clsx('SolutionIcon', className)}
      xmlns="http://www.w3.org/2000/svg"
      width={173}
      height={155}
      viewBox="0 0 155.53 173.06"
    >
      <defs>
        <style>{`
.SolutionIcon .cls-1 {
  fill: url(#SolutionIcon-linear-gradient);
}

.SolutionIcon .cls-2 {
  fill: #fff;
}

.SolutionIcon .cls-2, 
.SolutionIcon .cls-5 {
  stroke: #000;
}

.SolutionIcon .cls-2, 
.SolutionIcon .cls-4, 
.SolutionIcon .cls-5 {
  stroke-miterlimit: 10;
}

.SolutionIcon .cls-3 {
  fill: url(#SolutionIcon-linear-gradient-2);
}

.SolutionIcon .cls-4, .SolutionIcon .cls-5 {
  fill: none;
}

.SolutionIcon .cls-4 {
  stroke: #f9f3f1;
  stroke-linecap: round;
  stroke-width: 2px;
}

.SolutionIcon .cls-6 {
  fill: url(#SolutionIcon-linear-gradient-3);
}

.SolutionIcon .cls-7 {
  fill: url(#SolutionIcon-linear-gradient-4);
}
`}</style>
        <linearGradient
          id="SolutionIcon-linear-gradient"
          x1="66.35"
          y1="168.26"
          x2="89.18"
          y2="168.26"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#0f5c52" />
          <stop offset="1" stopColor="#0a423b" />
        </linearGradient>
        <linearGradient
          id="SolutionIcon-linear-gradient-2"
          x1="-22.96"
          y1="95.11"
          x2="81.53"
          y2="95.11"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f9f3f1" />
          <stop offset="0.58" stopColor="#fad69e" />
          <stop offset="1" stopColor="#fac05f" />
        </linearGradient>
        <linearGradient
          id="SolutionIcon-linear-gradient-3"
          x1="182.35"
          y1="78.18"
          x2="113.8"
          y2="78.46"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ffbe97" />
          <stop offset="0.67" stopColor="#fcad43" />
          <stop offset="1" stopColor="#faa519" />
        </linearGradient>
        <linearGradient
          id="SolutionIcon-linear-gradient-4"
          x1="58.31"
          y1="154.13"
          x2="97.22"
          y2="154.13"
          xlinkHref="#SolutionIcon-linear-gradient"
        />
      </defs>
      <g>
        <path className="cls-1" d="M66.35,163.45a11.58,11.58,0,0,0,22.83,0Z" />
        <line className="cls-2" x1="58.75" y1="151.05" x2="72.12" y2="151.05" />
        <path
          className="cls-3"
          d="M75.28,26.83a52.24,52.24,0,0,0-27.85,94.72,27,27,0,0,1,11.32,22v16a4,4,0,0,0,4,4H92.83a4,4,0,0,0,4-4v-16a27,27,0,0,1,11.31-22A52.25,52.25,0,0,0,75.28,26.83Z"
        />
        <g>
          <line className="cls-4" x1="1" y1="77.77" x2="14.93" y2="77.77" />
          <line
            className="cls-4"
            x1="140.6"
            y1="77.77"
            x2="154.53"
            y2="77.77"
          />
        </g>
        <g>
          <line className="cls-4" x1="23.48" y1="23.48" x2="33.34" y2="33.34" />
          <line
            className="cls-4"
            x1="122.2"
            y1="122.2"
            x2="132.05"
            y2="132.05"
          />
        </g>
        <line className="cls-4" x1="77.77" y1="1" x2="77.77" y2="14.93" />
        <g>
          <line
            className="cls-4"
            x1="132.05"
            y1="23.48"
            x2="122.2"
            y2="33.34"
          />
          <line
            className="cls-4"
            x1="33.34"
            y1="122.2"
            x2="23.48"
            y2="132.05"
          />
        </g>
        <path className="cls-5" d="M92.39,78.6a14.82,14.82,0,1,1-5.91-11.86" />
        <path
          id="Icon_awesome-cog"
          data-name="Icon awesome-cog"
          className="cls-6"
          d="M111.15,87.21,105,83.66a27.87,27.87,0,0,0,0-10.14L111.15,70a1.73,1.73,0,0,0,.8-2,36,36,0,0,0-7.9-13.66,1.73,1.73,0,0,0-2.14-.34L95.75,57.5A27.15,27.15,0,0,0,87,52.44V45.35a1.74,1.74,0,0,0-1.36-1.69,36.47,36.47,0,0,0-15.77,0,1.73,1.73,0,0,0-1.35,1.69v7.1a28.33,28.33,0,0,0-8.78,5.07L53.59,54a1.72,1.72,0,0,0-2.13.33A35.74,35.74,0,0,0,43.56,68a1.71,1.71,0,0,0,.79,2l6.16,3.55a27.87,27.87,0,0,0,0,10.14l-6.16,3.55a1.75,1.75,0,0,0-.79,2,36,36,0,0,0,7.9,13.66,1.75,1.75,0,0,0,2.13.33l6.16-3.55a27.24,27.24,0,0,0,8.78,5.06v7.11a1.72,1.72,0,0,0,1.36,1.69,36.12,36.12,0,0,0,15.76,0A1.72,1.72,0,0,0,87,111.85v-7.1a28.05,28.05,0,0,0,8.78-5.07l6.16,3.55a1.72,1.72,0,0,0,2.14-.33A35.77,35.77,0,0,0,112,89.24a1.77,1.77,0,0,0-.81-2Zm-33.4,2.93A11.55,11.55,0,1,1,89.3,78.59,11.55,11.55,0,0,1,77.75,90.14Z"
        />
        <path
          className="cls-7"
          d="M58.31,144.26H97.22a0,0,0,0,1,0,0V160a4,4,0,0,1-4,4H62.36a4,4,0,0,1-4-4V144.26A0,0,0,0,1,58.31,144.26Z"
        />
      </g>
    </svg>
  );
};

export default SolutionIcon;
