import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

type SearchIconProps = {
  className?: string;
};

const SearchIcon: FC<SearchIconProps> = ({ className }) => {
  return (
    <svg
      className={clsx(className)}
      xmlns="http://www.w3.org/2000/svg"
      width={111}
      height={111}
      viewBox="0 0 109.24 111.01"
    >
      <g>
        <g>
          <path
            fill="currentColor"
            d="M109.46,103.35l-6.13,6.13a5.18,5.18,0,0,1-7.35,0L74.36,87.87a5.24,5.24,0,0,1-1.52-3.69V80.65a45.08,45.08,0,1,1,7.81-7.81h3.53a5.24,5.24,0,0,1,3.69,1.52L109.48,96A5.22,5.22,0,0,1,109.46,103.35ZM72.84,45.09A27.75,27.75,0,1,0,45.09,72.84,27.73,27.73,0,0,0,72.84,45.09Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default SearchIcon;
