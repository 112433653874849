import { useIsMobile } from '@/lib';
import { useRefs } from '@/lib/hooks/useRefs';
import { calculateLineLength } from '@/lib/math';
import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import {
  FC,
  MutableRefObject,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useInView } from 'react-intersection-observer';
import { animated, useSpring } from 'react-spring';

type StepProps = {
  text: string;
  enabled: boolean;
  divRef?: MutableRefObject<HTMLDivElement>;
};

const Step: FC<StepProps> = ({ divRef, text, enabled }) => {
  const { opacity, pop } = useSpring({
    opacity: enabled ? 1 : 0,
    pop: enabled ? 1 : 0,
  });

  return (
    <div ref={divRef} className="relative flex mb-10">
      <svg
        className={clsx('min-w-5 mr-5 mt-1')}
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
      >
        <animated.g transform="translate(-488.873 -958.377)" opacity={opacity}>
          <g
            id="Ellipse_456"
            data-name="Ellipse 456"
            transform="translate(491.873 961.377)"
            fill="none"
            stroke="#057869"
            strokeMiterlimit="10"
            strokeWidth="3"
          >
            <circle cx="8" cy="8" r="8" stroke="none" fill="#fff" />
            <circle cx="8" cy="8" r="9.5" fill="none" />
          </g>
          <animated.circle
            cx="4.051"
            cy="4.051"
            r="4.051"
            transform="translate(495.822 965.326)"
            fill="#057869"
          />
        </animated.g>
      </svg>
      <animated.div
        style={{
          transform: pop
            .to({
              range: [0, 0.5, 1],
              output: [100, 120, 100],
            })
            .to((x) => `scale(${x}%)`),
        }}
      >
        <animated.div style={{ opacity }} className="text-black">
          <div className="text-lg">{text}</div>
        </animated.div>
      </animated.div>
    </div>
  );
};

const desktopElementsScrollThresholds = [
  0, 0.29, 0.337, 0.371, 0.525, 0.559, 0.587, 0.612, 0.647, 0.684, 0.719, 0.882,
  0.916, 0.963, 0.998, 1.033, 1.08,
];
const mobileElementsScrollThresholds = [
  0, 0.249, 0.293, 0.323, 0.487, 0.518, 0.542, 0.555, 0.588, 0.611, 0.647,
  0.767, 0.798, 0.835, 0.864, 0.894, 0.93,
];

type PostRevolutionSectionProps = {};

const PostRevolutionSection: FC<PostRevolutionSectionProps> = () => {
  const { ref: intersectionRef, inView: sectionInView } = useInView();
  const rootRef = useRef<HTMLDivElement>();
  const setRootRefs = useCallback(
    (node) => {
      // Ref's from useRef needs to have the node assigned to `current`
      rootRef.current = node;
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      intersectionRef(node);
    },
    [intersectionRef]
  );
  const {
    phoneImage,
    // clientsBgImage,
    clientsImage,
    // rocketBgImage,
    rocketImage,
  } = useStaticQuery(graphql`
    query {
      phoneImage: file(relativePath: { eq: "home/post_revolution_phone.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 512
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      clientsImage: file(
        relativePath: { eq: "home/post_revolution_clients.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 512
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      rocketImage: file(
        relativePath: { eq: "home/post_revolution_rocket.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 512
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  const isMobile = useIsMobile();
  const [numberOfVisibleSteps, setNumberOfVisibleSteps] = useState(0);

  const handleUpdateScrollPosition = useCallback(() => {
    const scrollPos =
      window.scrollY + window.innerHeight - rootRef.current?.offsetTop;
    const percentageVisible = Math.min(
      Math.max(scrollPos / (rootRef.current?.clientHeight ?? 1), 0),
      2
    );
    setNumberOfVisibleSteps(
      (isMobile
        ? mobileElementsScrollThresholds
        : desktopElementsScrollThresholds
      ).filter((it) => it <= percentageVisible).length
    );
  }, [isMobile, setNumberOfVisibleSteps]);

  useLayoutEffect(() => {
    if (sectionInView) {
      document.addEventListener('scroll', handleUpdateScrollPosition);
    } else {
      document.removeEventListener('scroll', handleUpdateScrollPosition);
    }

    return () => {
      document.removeEventListener('scroll', handleUpdateScrollPosition);
    };
  }, [sectionInView]);

  const { numberOfVisibleSteps: numberOfVisibleStepsSpring } = useSpring({
    numberOfVisibleSteps,
  });

  const parentRef = useRef<HTMLDivElement>();
  const stepRefs = useRefs<HTMLDivElement>(18);

  const bgLine = useMemo<{ x: number; y: number; stepNumber: number }[]>(() => {
    let line = [];
    if (parentRef.current) {
      line.push(
        ...stepRefs
          .filter((stepRef) => stepRef.current)
          .map((stepRef, idx) => ({
            x: stepRef.current.offsetLeft,
            y: stepRef.current.offsetTop,
            stepNumber: idx,
          }))
      );
      // move to the point
      line = line.map((p) => ({
        ...p,
        x: p.x + 10,
        y: p.y + 15,
      }));

      // add corners
      if (!isMobile) {
        line = line.flatMap((p, idx, self) => {
          if (idx + 1 < self.length && p.x !== self[idx + 1].x) {
            const lineHeight = (2 * p.y + self[idx + 1].y) / 3;
            return [
              p,
              {
                y: lineHeight,
                x: p.x,
              },
              {
                y: lineHeight,
                x: self[idx + 1].x,
              },
            ];
          }
          return [p];
        });
      }
    }
    return line;
  }, [parentRef.current?.clientWidth]);

  const lineLength = useMemo<number>(() => {
    if (bgLine.length < 2) return 0;
    const [p1, p2, ...rest] = bgLine;
    return calculateLineLength(p1, p2, ...rest);
  }, [bgLine]);

  return (
    <section
      ref={setRootRefs}
      className="min-h-screen w-screen flex justify-center items-center px-28 py-10"
    >
      <div
        ref={parentRef}
        className="relative flex flex-col width-full max-w-5xl"
      >
        <animated.svg
          className="absolute text-primary top-0 left-0"
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${parentRef.current?.clientWidth ?? 0} ${
            parentRef.current?.clientHeight ?? 0
          }`}
        >
          <animated.polyline
            points={bgLine.map((p) => ` ${p.x},${p.y}`).join(` `)}
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="transparent"
            strokeWidth={3}
            strokeDasharray={lineLength}
            strokeDashoffset={numberOfVisibleStepsSpring
              .to({
                range: [0, 17],
                output: [0, 1],
              })
              .to((x) => (1 - x) * lineLength)}
          />
        </animated.svg>

        <div className="font-semibold text-6xl text-center text-primary mb-24">
          Post Revolution
        </div>

        <div className="flex flex-col-reverse lg:flex-row items-center">
          <div className="flex-1 mr-14">
            <div className="font-semibold text-4xl mb-11 pl-10">
              How you&apos;ll operate
            </div>
            <Step
              text="You'll spend almost 0 time and effort on quoting"
              divRef={stepRefs[1]}
              enabled={numberOfVisibleSteps >= 1}
            />
            <Step
              text="You'll reallocate that saved time and effort to solve relevant issues that move the deal forward"
              divRef={stepRefs[2]}
              enabled={numberOfVisibleSteps >= 2}
            />
            <Step
              text="You'll easily manage your pipeline with your virtual assistant"
              divRef={stepRefs[3]}
              enabled={numberOfVisibleSteps >= 3}
            />
            <Step
              text="You'll Increase customer interactions and build stronger relationships"
              divRef={stepRefs[4]}
              enabled={numberOfVisibleSteps >= 4}
            />
          </div>

          <GatsbyImage
            image={getImage(phoneImage)}
            alt="hand holding phone as a revolution symbol"
            className="flex-1 mx-14"
          />
        </div>

        <div className="flex flex-col lg:flex-row items-center mt-24">
          {/* <GatsbyImage */}
          {/*  image={getImage(clientsBgImage)} */}
          {/*  alt="city skyline background" */}
          {/*  className="absolute -left-44 -right-44" */}
          {/* /> */}
          <GatsbyImage
            image={getImage(clientsImage)}
            alt="people walking with phone in hand"
            className="flex-1 mx-14"
          />

          <div className="flex-1 mr-14">
            <div className="font-semibold text-4xl mb-11 pl-10">
              How you&apos;ll interact with clients
            </div>
            <Step
              text="Easily show and choose the right products with them."
              divRef={stepRefs[5]}
              enabled={numberOfVisibleSteps >= 5}
            />
            <Step
              text="Build trust as you are transparent."
              divRef={stepRefs[6]}
              enabled={numberOfVisibleSteps >= 6}
            />
            <Step
              text="Create quotes on the spot."
              divRef={stepRefs[7]}
              enabled={numberOfVisibleSteps >= 7}
            />
            <Step
              text="Reach faster decisions through collaboration."
              divRef={stepRefs[8]}
              enabled={numberOfVisibleSteps >= 8}
            />
            <Step
              text="Help customers make informed decisions with detailed product showcases."
              divRef={stepRefs[9]}
              enabled={numberOfVisibleSteps >= 9}
            />
            <Step
              text="Make working with you easier, safer and justifiable."
              divRef={stepRefs[10]}
              enabled={numberOfVisibleSteps >= 10}
            />
          </div>
        </div>

        <div className="flex flex-col lg:flex-row items-center mt-24">
          {/* <GatsbyImage */}
          {/*   image={getImage(rocketBgImage)} */}
          {/*   alt="sky with stars background" */}
          {/*   className="absolute bottom-0 h-screen" */}
          {/* /> */}
          <div className="flex-1">
            <div className="font-semibold text-4xl mb-11 pl-10">
              Why will your customer satisfaction, closing rate and deal size
              skyrocket?
            </div>
            <Step
              text="Customers will get a quote that they can actually understand."
              divRef={stepRefs[11]}
              enabled={numberOfVisibleSteps >= 11}
            />
            <Step
              text="By letting them make choices and changes on their own you won't lose deals and create frustration."
              divRef={stepRefs[12]}
              enabled={numberOfVisibleSteps >= 12}
            />
            <Step
              text="Let them add their favorite accessories to products on their own (self cross-sell)"
              divRef={stepRefs[13]}
              enabled={numberOfVisibleSteps >= 13}
            />
            <Step
              text="Allow online quote acceptance and payments."
              divRef={stepRefs[14]}
              enabled={numberOfVisibleSteps >= 14}
            />
            <Step
              text="If the deal seems stuck, use heat-maps of the client's interactions with the quote to understand why."
              divRef={stepRefs[15]}
              enabled={numberOfVisibleSteps >= 15}
            />
            <Step
              text="Use these insights to your advantage and bring the deal home."
              divRef={stepRefs[16]}
              enabled={numberOfVisibleSteps >= 16}
            />
          </div>

          <GatsbyImage
            image={getImage(rocketImage)}
            alt="rocket taking off"
            className="flex-1"
          />
        </div>
      </div>
    </section>
  );
};

export default PostRevolutionSection;
