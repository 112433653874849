import heroAnimationJs from '@/images/home/hero.js.svg';
import * as React from 'react';
import { FC } from 'react';

type HeroSectionProps = {};

const HeroSection: FC<HeroSectionProps> = () => {
  return (
    <section className="min-h-screen w-screen overflow-x-hidden flex flex-col justify-center">
      <div className="overflow-x-hidden flex flex-col min-h-screen w-screen max-w-5xl m-auto">
        <div className="font-semibold text-3xl lg:text-5xl lg:leading-normal text-center text-primary mt-8 mx-4">
          Changing the Way Businesses Buy & Sell Equipment and Supplies
        </div>
        <div className="font-light text-gray-400 text-xl text-center mt-4 mx-4 lg:mx-20">
          OptiOffer is the revolutionary Supplier - Reseller Platform (SRP)
          <br />
          for sales professionals & entrepreneurs
        </div>
        <object className="flex-1" type="image/svg+xml" data={heroAnimationJs}>
          opti destroyer solving problems
        </object>
      </div>
    </section>
  );
};

export default HeroSection;
