import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

type CustomerIconProps = {
  className?: string;
};

const CustomerIcon: FC<CustomerIconProps> = ({ className }) => {
  return (
    <svg
      className={clsx(className)}
      xmlns="http://www.w3.org/2000/svg"
      width={111}
      height={111}
      viewBox="0 0 109.24 111.01"
    >
      <g>
        <g>
          <path
            fill="currentColor"
            d="M97.12,91.58v9A10.41,10.41,0,0,1,86.72,111H10.41A10.41,10.41,0,0,1,0,100.59v-9A29.14,29.14,0,0,1,29.14,62.44h3.62a37.73,37.73,0,0,0,31.61,0H68A29.14,29.14,0,0,1,97.12,91.58ZM20.81,27.75A27.75,27.75,0,1,1,48.56,55.5,27.75,27.75,0,0,1,20.81,27.75Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default CustomerIcon;
