import { EXPERIMENTS } from '@/abTestDefinitions';
import AbstractCardFlipSection from '@/components/home/AbstractCardFlipSection';
import step1 from '@/images/home/screen1/screen_1_01.svg';
import step2 from '@/images/home/screen1/screen_1_02.svg';
import step3 from '@/images/home/screen1/screen_1_03.svg';
import step4 from '@/images/home/screen1/screen_1_04.svg';
import step5 from '@/images/home/screen1/screen_1_05.svg';
import step6 from '@/images/home/screen1/screen_1_06.svg';
import step7 from '@/images/home/screen1/screen_1_07.svg';
import step8 from '@/images/home/screen1/screen_1_08.js.svg';
import step9 from '@/images/home/screen1/screen_1_09.svg';
import step10 from '@/images/home/screen1/screen_1_10.svg';
import { useSafeExperiment } from '@/lib/hooks/useSafeExperiment';
import * as React from 'react';
import { FC, useMemo } from 'react';

type QuotingRevolutionSectionProps = {};

const allItems = [
  {
    image: null,
    imageSrc: step1,
    header:
      "You won't waste time on searching for product data in multiple catalogs and price lists ever again",
    body: 'Have all your products & their details centralized to find them instantly',
  },
  {
    image: null,
    imageSrc: step2,
    header: 'Keeping catalogs and price lists in a single place reduces errors',
    body: 'Opti Flawlessly pulls product specs, descriptions, accessories, images, and prices',
  },
  {
    image: null,
    imageSrc: step3,
    header: 'Add your product in seconds',
    body: 'Stop copy-pasting from catalogs to documents',
  },
  {
    image: null,
    imageSrc: step4,
    header: 'Maximize you cross-selling potential',
    body: 'Your product accessories are one click away',
  },
  {
    image: null,
    imageSrc: step5,
    header: 'Increase your earnings at every quote',
    body: 'Easily add one time services for products and quotes',
  },
  {
    image: null,
    imageSrc: step6,
    header: "Always be sure the quote you're sending is 100% correct",
    body: 'Have a birds eye view before sending it',
  },
  {
    image: null,
    imageSrc: step7,
    header: 'Put your knowledge to work',
    body: 'Catch your customers attention with a custom message that pulls them closer',
  },
  {
    image: (
      <object className="flex-1" type="image/svg+xml" data={step8}>
        screen_1_08
      </object>
    ),
    header: "Make sure your customers don't forget who you are and what you do",
    body: 'You will now have fully branded custom quotes',
  },
  {
    image: null,
    imageSrc: step9,
    header: 'Never let a customer confused about what they are paying for',
    body: 'Present your products in an visually appealing way with all relevant descriptions, specs, prices and images',
  },
  {
    image: null,
    imageSrc: step10,
    header: "Don't be forgotten by your customer",
    body: 'You and your company are now always visible where customers are looking',
  },
];

const QuotingRevolutionSection: FC<QuotingRevolutionSectionProps> = () => {
  const { selectVariant, activeVariant } = useSafeExperiment(
    EXPERIMENTS['211031 - Homepage Length']
  );

  const items = useMemo(
    () =>
      selectVariant({
        [EXPERIMENTS['211031 - Homepage Length'].variants[0]]: allItems,
        [EXPERIMENTS['211031 - Homepage Length'].variants[1]]: [
          allItems[0],
          allItems[3],
          allItems[4],
        ],
      }),
    [activeVariant]
  );

  return (
    <AbstractCardFlipSection
      title="Why will your customer satisfaction, closing rate and deal size skyrocket?"
      items={items}
    />
  );
};

export default QuotingRevolutionSection;
