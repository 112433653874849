import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import CompetitionSection from '@/components/home/CompetitionSection';
import EyesSection from '@/components/home/EyesSection';
import HeroSection from '@/components/home/HeroSection';
import JoinBetaSection from '@/components/home/JoinBeta';
import NextLevelSection from '@/components/home/NextLevelSection';
import PostRevolutionSection from '@/components/home/PostRevolutionSection';
import QuotingRevolutionSection from '@/components/home/QuotingRevolutionSection';
import SnakeSection from '@/components/home/SnakeSection';
import VirtualAssistantSection from '@/components/home/VirtualAssistantSection';
import circle1 from '@/images/home/bg_circle_1.svg';
import circle2 from '@/images/home/bg_circle_2.svg';
import circle3 from '@/images/home/bg_circle_3.svg';
import { PageProps } from 'gatsby';
import * as React from 'react';
import { FC, useCallback, useLayoutEffect } from 'react';
import { animated, useSpring } from 'react-spring';

const numberOfSteps = 25;
const possibleElements = [circle1, circle2, circle3];
const bgElements = new Array(numberOfSteps).fill(null).map((q, idx) => ({
  key: `background-circle-${idx}}`,
  src: possibleElements[
    Math.round(Math.random() * (possibleElements.length - 1))
  ],
  top: Math.random() * 88 + 5,
  left: Math.random() * 105 - 40,
  parallaxSpeed: Math.random() * 2 - 1,
}));

const ForResellers: FC<PageProps> = () => {
  const [{ parallax }, springApi] = useSpring(() => ({ parallax: 0 }));

  const handleUpdateScrollPosition = useCallback(() => {
    springApi.start({ parallax: window.scrollY });
  }, [springApi]);
  useLayoutEffect(() => {
    document.addEventListener('scroll', handleUpdateScrollPosition);

    return () => {
      springApi.stop();
      document.removeEventListener('scroll', handleUpdateScrollPosition);
    };
  }, [springApi]);

  return (
    <Layout className="bg-lightPeach relative">
      <SEO
        url="for-resellers"
        title="The revolutionary Supplier - Reseller Platform"
        description="OptiOffer changes the way businesses buy & sell equipment and supplies by revolutionizing how catalogs work to achieve connected selling and streamlined buying."
      />

      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        {bgElements.map(({ key, src, top, left, parallaxSpeed }) => (
          <animated.img
            className="absolute"
            style={{
              top: `${top}%`,
              left: `${left}%`,
              transform: parallax.to(
                (v) => `translateY(${v * parallaxSpeed}px)`
              ),
            }}
            key={key}
            src={src}
            alt={key}
          />
        ))}
      </div>

      <HeroSection />
      <SnakeSection />
      <EyesSection />
      <QuotingRevolutionSection />
      <NextLevelSection />
      <VirtualAssistantSection />
      <PostRevolutionSection />
      <CompetitionSection />
      <JoinBetaSection />
    </Layout>
  );
};

export default ForResellers;
