export type Point = {
  x: number;
  y: number;
};

export function calculateLineLength(
  p1: Point,
  p2: Point,
  ...rest: Point[]
): number {
  const p1ToP2 = !p1 || !p2 ? 0 : Math.hypot(p2.x - p1.x, p2.y - p1.y);
  if (!rest.length) {
    return p1ToP2;
  }
  return p1ToP2 + calculateLineLength(p2, rest[0], ...rest.slice(1));
}
