import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

type CopyIconProps = {
  className?: string;
};

const CopyIcon: FC<CopyIconProps> = ({ className }) => {
  return (
    <svg
      className={clsx(className)}
      xmlns="http://www.w3.org/2000/svg"
      width={111}
      height={111}
      viewBox="0 0 109.24 111.01"
    >
      <g>
        <g>
          <path
            fill="currentColor"
            d="M27.75,90.19H5.2A5.2,5.2,0,0,1,0,85V12.14a5.2,5.2,0,0,1,5.2-5.2H22.68a13.86,13.86,0,0,1,24,0H64.17a5.2,5.2,0,0,1,5.21,5.2V27.75H39.89A12.16,12.16,0,0,0,27.75,39.89Zm1.73-76.31a5.21,5.21,0,1,0,5.21-5.21A5.2,5.2,0,0,0,29.48,13.88ZM97.12,62.44V105.8a5.2,5.2,0,0,1-5.2,5.2h-52a5.2,5.2,0,0,1-5.2-5.2V39.89a5.2,5.2,0,0,1,5.2-5.2H69.38V57.23a5.22,5.22,0,0,0,5.2,5.21Zm0-6.94H76.31V34.69h1.32a5.18,5.18,0,0,1,3.67,1.52l14.3,14.3a5.18,5.18,0,0,1,1.52,3.68Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default CopyIcon;
