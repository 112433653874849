import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

type CalculateIconProps = {
  className?: string;
};

const CalculateIcon: FC<CalculateIconProps> = ({ className }) => {
  return (
    <svg
      className={clsx(className)}
      xmlns="http://www.w3.org/2000/svg"
      width={111}
      height={111}
      viewBox="0 0 109.24 111.01"
    >
      <g>
        <g>
          <path
            fill="currentColor"
            d="M97.12,10.41v90.18A10.67,10.67,0,0,1,86.72,111H10.41A10.68,10.68,0,0,1,0,100.59V10.41A10.68,10.68,0,0,1,10.41,0H86.72A10.67,10.67,0,0,1,97.12,10.41ZM83.25,16.65a3,3,0,0,0-2.78-2.77H16.65a3,3,0,0,0-2.77,2.77v22.2a3,3,0,0,0,2.77,2.77H80.47a3,3,0,0,0,2.78-2.77ZM27.75,58.27A3,3,0,0,0,25,55.5H16.65a3,3,0,0,0-2.77,2.77V66.6a3,3,0,0,0,2.77,2.78H25a3,3,0,0,0,2.78-2.78Zm0,27.75A3,3,0,0,0,25,83.25H16.65A3,3,0,0,0,13.88,86v8.33a3,3,0,0,0,2.77,2.77H25a3,3,0,0,0,2.78-2.77ZM55.5,58.27a3,3,0,0,0-2.78-2.77H44.4a3,3,0,0,0-2.78,2.77V66.6a3,3,0,0,0,2.78,2.78h8.32A3,3,0,0,0,55.5,66.6ZM55.5,86a3,3,0,0,0-2.78-2.77H44.4A3,3,0,0,0,41.62,86v8.33a3,3,0,0,0,2.78,2.77h8.32a3,3,0,0,0,2.78-2.77ZM83.25,58.27a3,3,0,0,0-2.78-2.77H72.15a3,3,0,0,0-2.77,2.77V94.35a3,3,0,0,0,2.77,2.77h8.32a3,3,0,0,0,2.78-2.77Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default CalculateIcon;
