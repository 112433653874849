import CalculateIcon from '@/images/home/CalculateIcon';
import CheckIcon from '@/images/home/CheckIcon';
import CopyIcon from '@/images/home/CopyIcon';
import CustomerIcon from '@/images/home/CustomerIcon';
import ExcelIcon from '@/images/home/ExcelIcon';
import FlagIcon from '@/images/home/FlagIcon';
import SearchIcon from '@/images/home/SearchIcon';
import SendIcon from '@/images/home/SendIcon';
import TermsIcon from '@/images/home/TermsIcon';
import { useIsMobile } from '@/lib';
import { useRefs } from '@/lib/hooks/useRefs';
import { calculateLineLength } from '@/lib/math';
import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import {
  FC,
  MutableRefObject,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useInView } from 'react-intersection-observer';
import { animated, useSpring } from 'react-spring';

type StepProps = {
  time?: string;
  line1: string;
  line2: string;
  Image: FC<{ className?: string }>;
  enabled: boolean;
  divRef?: MutableRefObject<HTMLDivElement>;
};

const Step: FC<StepProps> = ({
  divRef,
  time,
  line1,
  line2,
  Image,
  enabled,
}) => {
  const { color, textOpacity, dotOpacity, pop } = useSpring({
    color: enabled ? `#057869` : `#F5855E`,
    textOpacity: enabled ? 1 : 0,
    dotOpacity: enabled ? 1 : 0,
    pop: enabled ? 1 : 0,
  });

  return (
    <div
      ref={divRef}
      className="relative flex flex-col justify-end h-36 lg:w-80 pl-10 lg:pl-0 mb-12 lg:mb-0"
    >
      <animated.div
        style={{
          color,
          transform: pop
            .to({
              range: [0, 0.5, 1],
              output: [100, 120, 100],
            })
            .to((x) => `scale(${x}%)`),
        }}
      >
        <animated.div
          style={{
            opacity: pop.to({
              range: [0, 0.5, 1],
              output: [0, 0.3, 0.1],
            }),
          }}
        >
          <Image className={clsx(`absolute bottom-0 left-3`)} />
        </animated.div>
        <animated.div style={{ opacity: textOpacity }} className="text-black">
          <div className="text-lg">{time}</div>
          <div className="font-semibold text-lg h-6">{line1}</div>
          <div className="font-semibold text-lg h-6">{line2}</div>
        </animated.div>
      </animated.div>
      <animated.svg
        className={clsx(`transition-opacity -mt-8 lg:mt-5 -ml-10 lg:ml-0`)}
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
      >
        <g transform="translate(-488.873 -958.377)">
          <animated.g
            id="Ellipse_456"
            data-name="Ellipse 456"
            transform="translate(491.873 961.377)"
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            strokeWidth="3"
            opacity={dotOpacity}
          >
            <circle cx="8" cy="8" r="8" stroke="none" fill="#fff" />
            <circle cx="8" cy="8" r="9.5" fill="none" />
          </animated.g>
          <animated.circle
            opacity={dotOpacity}
            cx="4.051"
            cy="4.051"
            r="4.051"
            transform="translate(495.822 965.326)"
            fill="#057869"
          />
        </g>
      </animated.svg>
    </div>
  );
};

const desktopElementsScrollThresholds = [
  0, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1,
];
const mobileElementsScrollThresholds = [
  0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0,
];

type SnakeSectionProps = {};

const SnakeSection: FC<SnakeSectionProps> = () => {
  const { ref: intersectionRef, inView: sectionInView } = useInView();
  const rootRef = useRef<HTMLDivElement>();
  const setRootRefs = useCallback(
    (node) => {
      // Ref's from useRef needs to have the node assigned to `current`
      rootRef.current = node;
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      intersectionRef(node);
    },
    [intersectionRef]
  );
  const { clockImage } = useStaticQuery(graphql`
    query {
      clockImage: file(relativePath: { eq: "home/clock.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 285
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  const isMobile = useIsMobile();
  const [numberOfVisibleSteps, setNumberOfVisibleSteps] = useState(0);

  const handleUpdateScrollPosition = useCallback(() => {
    const scrollPos =
      window.scrollY + window.innerHeight - rootRef.current?.offsetTop;
    const percentageVisible = Math.min(
      Math.max(scrollPos / (rootRef.current?.clientHeight ?? 1), 0),
      2
    );
    setNumberOfVisibleSteps(
      (isMobile
        ? mobileElementsScrollThresholds
        : desktopElementsScrollThresholds
      ).filter((it) => it <= percentageVisible).length
    );
  }, [isMobile, setNumberOfVisibleSteps]);

  useLayoutEffect(() => {
    if (sectionInView) {
      document.addEventListener('scroll', handleUpdateScrollPosition);
    } else {
      document.removeEventListener('scroll', handleUpdateScrollPosition);
    }

    return () => {
      document.removeEventListener('scroll', handleUpdateScrollPosition);
    };
  }, [sectionInView]);

  const clockEnabled = numberOfVisibleSteps >= 9;
  const { clockScale, time } = useSpring({
    clockScale: clockEnabled ? 1 : 0,
    time: clockEnabled ? 105 : 0,
    config: {
      duration: 2000,
    },
  });

  const { numberOfVisibleSteps: numberOfVisibleStepsSpring } = useSpring({
    numberOfVisibleSteps,
  });

  const parentRef = useRef<HTMLDivElement>();
  const stepRefs = useRefs<HTMLDivElement>(10);

  const bgSnake = useMemo<
    { x: number; y: number; stepNumber: number }[]
  >(() => {
    let line = [];
    if (parentRef.current) {
      line.push(
        ...stepRefs
          .filter((stepRef) => stepRef.current)
          .map((stepRef, idx) => ({
            x: stepRef.current.offsetLeft,
            y: stepRef.current.offsetTop,
            stepNumber: idx,
          }))
      );

      // move to the point
      line = line.map((p) => ({
        ...p,
        x: p.x + 11,
        y: p.y + 133,
      }));

      const margin = 2;
      // add corners
      if (!isMobile) {
        line = line.flatMap((p, idx, self) => {
          if (idx + 1 < self.length && p.y !== self[idx + 1].y) {
            return [
              p,
              {
                y: p.y,
                x:
                  p.x > parentRef.current?.clientWidth / 2
                    ? parentRef.current?.clientWidth - margin
                    : margin,
                stepNumber: p.stepNumber + 1,
              },
              {
                y: self[idx + 1].y,
                x:
                  p.x > parentRef.current?.clientWidth / 2
                    ? parentRef.current?.clientWidth - margin
                    : margin,
                corner: true,
                stepNumber: p.stepNumber + 1,
              },
            ];
          }
          return [p];
        });
      }
    }
    return line;
  }, [parentRef.current?.clientWidth, isMobile]);

  const snakeLength = useMemo<number>(() => {
    if (bgSnake.length < 2) return 0;
    const [p1, p2, ...rest] = bgSnake;
    return calculateLineLength(p1, p2, ...rest);
  }, [bgSnake]);

  return (
    <section
      ref={setRootRefs}
      className="min-h-screen w-screen flex justify-center items-center px-4 lg:px-28 py-10"
    >
      <div ref={parentRef} className="relative flex flex-col w-full max-w-5xl">
        <animated.svg
          className="absolute text-primary top-0 left-0"
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${parentRef.current?.clientWidth ?? 0} ${
            parentRef.current?.clientHeight ?? 0
          }`}
        >
          <animated.polyline
            points={bgSnake.map((p) => ` ${p.x},${p.y}`).join(` `)}
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="transparent"
            strokeWidth={3}
            strokeDasharray={snakeLength}
            strokeDashoffset={numberOfVisibleStepsSpring
              .to({
                range: [0, 9],
                output: [0, 1],
              })
              .to((x) => (1 - x) * snakeLength)}
          />
        </animated.svg>
        <div className="self-center max-w-3xl font-semibold text-2xl lg:text-4xl text-center px-4 pt-4 lg:pt-12">
          The Legacy Quoting Process
        </div>

        <div className="w-full flex-col lg:flex-row flex min-h-1/2screen scroll-snap-start items-center whitespace-nowrap">
          <Step
            time=" "
            line1="Ready to start the"
            line2="quote for the client"
            Image={FlagIcon}
            enabled={numberOfVisibleSteps >= 1}
            divRef={stepRefs[1]}
          />
          <Step
            time="5 min"
            line1="Open"
            line2="quote"
            Image={ExcelIcon}
            enabled={numberOfVisibleSteps >= 2}
            divRef={stepRefs[2]}
          />
          <Step
            time="5 min"
            line1="Add customer"
            line2="details"
            Image={CustomerIcon}
            enabled={numberOfVisibleSteps >= 3}
            divRef={stepRefs[3]}
          />
        </div>
        <div className="w-full flex flex-col lg:flex-row-reverse min-h-1/2screen scroll-snap-start items-center">
          <Step
            time="20 min"
            line1="Search each"
            line2="product"
            Image={SearchIcon}
            enabled={numberOfVisibleSteps >= 4}
            divRef={stepRefs[4]}
          />
          <Step
            time="60 min"
            line1="Copy & Paste"
            line2="product data"
            Image={CopyIcon}
            enabled={numberOfVisibleSteps >= 5}
            divRef={stepRefs[5]}
          />
          <Step
            time="5 min"
            line1="Calculate"
            line2="prices"
            Image={CalculateIcon}
            enabled={numberOfVisibleSteps >= 6}
            divRef={stepRefs[6]}
          />
        </div>
        <div className="w-full flex flex-col lg:flex-row justify-center items-center min-h-1/2screen scroll-snap-start">
          <Step
            time="10 min"
            line1="Add terms"
            line2="& conditions"
            Image={TermsIcon}
            enabled={numberOfVisibleSteps >= 7}
            divRef={stepRefs[7]}
          />
          <Step
            time="5 min"
            line1="Review quote"
            line2=" "
            Image={CheckIcon}
            enabled={numberOfVisibleSteps >= 8}
            divRef={stepRefs[8]}
          />
        </div>
        <div className="w-full flex flex-col lg:flex-row-reverse lg:justify-around min-h-1/2screen items-center scroll-snap-start mb-20">
          <Step
            time="5 min"
            line1="Convert, Save, Attach,"
            line2="Write Message, Send"
            Image={SendIcon}
            enabled={numberOfVisibleSteps >= 9}
            divRef={stepRefs[9]}
          />
          <animated.div
            className="w-56 h-80 flex items-center justify-center relative"
            style={{
              transform: clockScale
                .to({
                  range: [0, 0.5, 1],
                  output: [0, 1.2, 1],
                })
                .to((x) => `scale(${x})`),
            }}
          >
            <GatsbyImage
              image={getImage(clockImage)}
              alt="clock showing 1:45h on average"
              style={{ position: 'absolute' }}
              className="absolute bottom-0 left-0 right-0 z-0"
            />

            <div className="text-white text-center relative z-10 pt-20">
              <animated.span className="font-semibold text-3xl">
                {time.to((t) => `${Math.floor(t / 60)}:${Math.floor(t % 60)}h`)}
              </animated.span>
              <br />
              <span className="text-base">on average</span>
            </div>
          </animated.div>
        </div>
      </div>
    </section>
  );
};

export default SnakeSection;
