import { ABExperiment } from '@/abTestDefinitions';
import { emitter, useExperiment } from '@marvelapp/react-ab-test';

export function useSafeExperiment(
  experiment: ABExperiment
): ReturnType<useExperiment> {
  if (typeof window === 'undefined') {
    emitter.setActiveVariant(experiment.id, experiment.variants[0]);
  }

  return useExperiment(experiment.id);
}
