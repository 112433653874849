import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

type FlagIconProps = {
  className?: string;
};

const FlagIcon: FC<FlagIconProps> = ({ className }) => {
  return (
    <svg
      className={clsx(className)}
      xmlns="http://www.w3.org/2000/svg"
      width={111}
      height={111}
      viewBox="0 0 109.24 111.01"
    >
      <g>
        <g>
          <path
            fill="currentColor"
            d="M109.24,20.8V73.5a6.91,6.91,0,0,1-3,5.73c-7.76,5.33-16.11,8.86-26.56,8.86-14.61,0-24.19-7.54-35.81-7.54a60.49,60.49,0,0,0-24.8,4.79v20.47a5.19,5.19,0,0,1-5.2,5.2H10.41a5.19,5.19,0,0,1-5.21-5.2V22.1a12.14,12.14,0,1,1,18.28-5.61,42.09,42.09,0,0,1,14.74-2.6c14.61,0,24.22,7.54,35.82,7.54C82.82,21.43,92,18,99.4,14.51A6.93,6.93,0,0,1,109.24,20.8ZM98.86,26.13A76.59,76.59,0,0,1,82.9,31V46.6A41.26,41.26,0,0,1,67,46V31.19c-5.73-1-10.82-3-16-4.62V41.18a36.88,36.88,0,0,0-16-1.24V24.42c-5.09.48-8.75,2.13-16,4.77V44.48c6.29-2.32,11.1-3.86,16-4.54V55.12a48.33,48.33,0,0,1,16,.82V70.79c5.7,1,10.78,3,16,4.62V60.8A36.51,36.51,0,0,0,82.9,62V77.54a35.06,35.06,0,0,0,16-5.88V56.38A40.13,40.13,0,0,1,82.9,62V46.6c5.92-1,10.84-3.06,16-5.18ZM35,55.12a62.3,62.3,0,0,0-16,3.77V74.18a68,68,0,0,1,16-3.6ZM67,46V60.78c-5.27-1.45-10.3-3.56-16-4.84V41.18C56.24,42.63,61.31,44.74,67,46Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default FlagIcon;
