import { EXPERIMENTS } from '@/abTestDefinitions';
import AbstractCardFlipSection from '@/components/home/AbstractCardFlipSection';
import step1 from '@/images/home/screen3/screen_3_01.svg';
import step2 from '@/images/home/screen3/screen_3_02.svg';
import step3 from '@/images/home/screen3/screen_3_03.svg';
import step4 from '@/images/home/screen3/screen_3_04.js.svg';
import { useSafeExperiment } from '@/lib/hooks/useSafeExperiment';
import * as React from 'react';
import { FC, useMemo } from 'react';

type VirtualAssistantSectionProps = {};
const allItems = [
  {
    image: null,
    imageSrc: step1,
    header: "Don't wonder if the customer read your quote",
    body: 'Get notified when the customer opened it',
  },
  {
    image: null,
    imageSrc: step2,
    header: 'Stop betting your deal on hunches',
    body: 'Know how clients interact with the quote through heat-maps and time spent on page',
  },
  {
    image: null,
    imageSrc: step3,
    header: 'Never forget to follow-up on a quote ever again',
    body: "Focus on the next tasks, the Virtual Assistant will notify you to follow-up on what you've sent",
  },
  {
    image: (
      <object className="flex-1" type="image/svg+xml" data={step4}>
        screen_3_04
      </object>
    ),
    header: "Don't lose deals because you strive for more",
    body: 'The Virtual Assistant will remind you if you drafted a quote but did not send it',
  },
];

const VirtualAssistantSection: FC<VirtualAssistantSectionProps> = () => {
  const { selectVariant, activeVariant } = useSafeExperiment(
    EXPERIMENTS['211031 - Homepage Length']
  );

  const items = useMemo(
    () =>
      selectVariant({
        [EXPERIMENTS['211031 - Homepage Length'].variants[0]]: allItems,
        [EXPERIMENTS['211031 - Homepage Length'].variants[1]]: [
          allItems[1],
          allItems[2],
        ],
      }),
    [activeVariant]
  );

  return (
    <AbstractCardFlipSection
      id="virtualAssistant"
      title="Focus your attention to selling, now you have a virtual assistant"
      items={items}
    />
  );
};

export default VirtualAssistantSection;
