import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

type ExcelIconProps = {
  className?: string;
};

const ExcelIcon: FC<ExcelIconProps> = ({ className }) => {
  return (
    <svg
      className={clsx(className)}
      xmlns="http://www.w3.org/2000/svg"
      width={111}
      height={111}
      viewBox="0 0 109.24 111.01"
    >
      <g>
        <g>
          <path
            fill="currentColor"
            d="M53.77,34.69H83.25V105.8a5.19,5.19,0,0,1-5.2,5.2H5.2A5.19,5.19,0,0,1,0,105.8V5.2A5.19,5.19,0,0,1,5.2,0H48.56V29.48A5.22,5.22,0,0,0,53.77,34.69ZM59.4,48.58H51.86A2.61,2.61,0,0,0,49.56,50C43,62.26,41.62,64.8,41.62,64.8,38,57.23,39.35,60.53,33.69,50a2.6,2.6,0,0,0-2.3-1.37H23.85a2.6,2.6,0,0,0-2.19,4L34.73,72.87,21.66,93.14a2.6,2.6,0,0,0,2.19,4h7.56a2.57,2.57,0,0,0,2.28-1.37c5.77-10.58,6.55-11.71,7.93-14.91,0,0,3.67,7,7.92,14.89a2.61,2.61,0,0,0,2.3,1.36H59.4a2.6,2.6,0,0,0,2.19-4l-13-20.27,13-20.27A2.59,2.59,0,0,0,59.4,48.58ZM83.25,27.75H55.5V0h1.32a5.2,5.2,0,0,1,3.69,1.52L81.73,22.76a5.18,5.18,0,0,1,1.52,3.67Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default ExcelIcon;
