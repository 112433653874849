import clsx from 'clsx';
import * as React from 'react';
import { FC, ReactNode } from 'react';

type EyesIconProps = {
  className?: string;
  leftEye: ReactNode;
  rightEye: ReactNode;
};

const EyesIcon: FC<EyesIconProps> = ({ className, leftEye, rightEye }) => {
  return (
    <svg
      className={clsx('EyesIcon', className)}
      xmlns="http://www.w3.org/2000/svg"
      width={235}
      height={95}
      viewBox="0 0 234.81 94.76"
    >
      <defs>
        <style>{`
.EyesIcon .cls-1 {
  fill: #f9f3f1;
}

.EyesIcon .cls-2 {
  fill: url(#EyesIcon-linear-gradient);
}

.EyesIcon .cls-3 {
  fill: url(#EyesIcon-linear-gradient-2);
}

.EyesIcon .cls-4 {
  fill: url(#EyesIcon-linear-gradient-3);
}

.EyesIcon .cls-5 {
  fill: url(#EyesIcon-linear-gradient-4);
}

.EyesIcon .cls-6 {
  fill: url(#EyesIcon-linear-gradient-5);
}
        `}</style>
        <linearGradient
          id="EyesIcon-linear-gradient"
          x1="74.47"
          y1="70.07"
          x2="-38.55"
          y2="70.07"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#0f5c52" />
          <stop offset="1" stopColor="#0a423b" />
        </linearGradient>
        <linearGradient
          id="EyesIcon-linear-gradient-2"
          x1="46.61"
          y1="133.29"
          x2="46.84"
          y2="73.67"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ffbe97" />
          <stop offset="0.67" stopColor="#fcad43" />
          <stop offset="1" stopColor="#faa519" />
        </linearGradient>
        <linearGradient
          id="EyesIcon-linear-gradient-3"
          x1="210.98"
          y1="70.07"
          x2="114.11"
          y2="70.07"
          xlinkHref="#EyesIcon-linear-gradient"
        />
        <linearGradient
          id="EyesIcon-linear-gradient-4"
          x1="168.17"
          y1="133.76"
          x2="168.4"
          y2="74.14"
          xlinkHref="#EyesIcon-linear-gradient-2"
        />
        <linearGradient
          id="EyesIcon-linear-gradient-5"
          x1="284.26"
          y1="8.98"
          x2="194.53"
          y2="27.63"
          xlinkHref="#EyesIcon-linear-gradient-2"
        />
      </defs>
      <g>
        <g>
          <path
            className="cls-1"
            d="M67.71,60.17a66.59,66.59,0,0,0-20.88-3.28A66.53,66.53,0,0,0,26,60.17a23.56,23.56,0,0,0-2.71,11,24,24,0,0,0,.58,5.27,23.6,23.6,0,0,0,46,0,24,24,0,0,0,.59-5.27A23.56,23.56,0,0,0,67.71,60.17Z"
          />
          {leftEye}
        </g>
        <path
          className="cls-3"
          d="M91.85,77.9a1.82,1.82,0,0,1-1.47-.75C81.73,65.38,65,58.07,46.83,58.07S11.94,65.38,3.28,77.15A1.82,1.82,0,1,1,.35,75C9.68,62.32,27.49,54.44,46.83,54.44S84,62.32,93.31,75a1.82,1.82,0,0,1-.38,2.54A1.84,1.84,0,0,1,91.85,77.9Z"
        />
        <g>
          <path
            className="cls-1"
            d="M189.27,60.17a68.07,68.07,0,0,0-41.75,0,23.57,23.57,0,0,0-2.72,11,24,24,0,0,0,.59,5.27,23.6,23.6,0,0,0,46,0,24,24,0,0,0,.59-5.27A23.56,23.56,0,0,0,189.27,60.17Z"
          />
          {rightEye}
        </g>
        <path
          className="cls-5"
          d="M213.41,77.9a1.82,1.82,0,0,1-1.47-.75c-8.65-11.77-25.34-19.08-43.55-19.08s-34.9,7.31-43.55,19.08A1.82,1.82,0,1,1,121.91,75c9.33-12.68,27.14-20.56,46.48-20.56S205.55,62.32,214.87,75a1.81,1.81,0,0,1-.39,2.54A1.79,1.79,0,0,1,213.41,77.9Z"
        />
        <path
          className="cls-6"
          d="M216.54,30.8v-.9c0-4.26,1.51-6.9,6-11.39,2.56-2.6,3.64-4.51,3.64-6.76,0-2.89-1.54-4.95-4.72-4.95-2.93,0-4.69,1.73-5.23,5.33a1.35,1.35,0,0,1-1.33,1.16h-5.41a1.35,1.35,0,0,1-1.35-1.5C208.8,5.49,212.48,0,221.73,0c10.1.06,13.08,6.15,13.08,11.75,0,3.77-1.11,6.81-5.62,11.34-2.61,2.62-3.63,4.4-3.63,7v.68Zm0,11.71V37.25a1.34,1.34,0,0,1,1.35-1.35h6.32a1.34,1.34,0,0,1,1.35,1.35v5.26a1.34,1.34,0,0,1-1.35,1.35h-6.32A1.34,1.34,0,0,1,216.54,42.51Z"
        />
      </g>
    </svg>
  );
};

export default EyesIcon;
