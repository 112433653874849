import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

type QuoteIconProps = {
  className?: string;
};

const QuoteIcon: FC<QuoteIconProps> = ({ className }) => {
  return (
    <svg
      className={clsx('QuoteIcon', className)}
      xmlns="http://www.w3.org/2000/svg"
      width={122}
      height={151}
      viewBox="0 0 121.85 151.05"
    >
      <defs>
        <style>{`
.QuoteIcon .cls-1 {
  fill: url(#QuoteIcon-linear-gradient);
}

.QuoteIcon .cls-2 {
  fill: url(#QuoteIcon-linear-gradient-2);
}

.QuoteIcon .cls-3 {
  fill: url(#QuoteIcon-linear-gradient-3);
}

.QuoteIcon .cls-4 {
  fill: url(#QuoteIcon-linear-gradient-4);
}

.QuoteIcon .cls-5 {
  fill: url(#QuoteIcon-linear-gradient-5);
}

.QuoteIcon .cls-6 {
  fill: url(#QuoteIcon-linear-gradient-6);
}

.QuoteIcon .cls-7 {
  fill: url(#QuoteIcon-linear-gradient-7);
}

.QuoteIcon .cls-8 {
  fill: url(#QuoteIcon-linear-gradient-9);
}

.QuoteIcon .cls-9 {
  fill: #f9f3f1;
}

.QuoteIcon .cls-10 {
  fill: url(#QuoteIcon-linear-gradient-10);
}
`}</style>
        <linearGradient
          id="QuoteIcon-linear-gradient"
          x1="-56.68"
          y1="75.53"
          x2="38.51"
          y2="75.53"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f9f3f1" />
          <stop offset="0.58" stopColor="#fad69e" />
          <stop offset="1" stopColor="#fac05f" />
        </linearGradient>
        <linearGradient
          id="QuoteIcon-linear-gradient-2"
          x1="213.89"
          y1="35.65"
          x2="64.69"
          y2="35.65"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ffbe97" />
          <stop offset="0.67" stopColor="#fcad43" />
          <stop offset="1" stopColor="#faa519" />
        </linearGradient>
        <linearGradient
          id="QuoteIcon-linear-gradient-3"
          x1="213.89"
          y1="47.97"
          x2="64.69"
          y2="47.97"
          xlinkHref="#QuoteIcon-linear-gradient-2"
        />
        <linearGradient
          id="QuoteIcon-linear-gradient-4"
          x1="213.89"
          y1="89.82"
          x2="64.69"
          y2="89.82"
          xlinkHref="#QuoteIcon-linear-gradient-2"
        />
        <linearGradient
          id="QuoteIcon-linear-gradient-5"
          x1="213.89"
          y1="102.14"
          x2="64.69"
          y2="102.14"
          xlinkHref="#QuoteIcon-linear-gradient-2"
        />
        <linearGradient
          id="QuoteIcon-linear-gradient-6"
          x1="213.89"
          y1="60.29"
          x2="64.69"
          y2="60.29"
          xlinkHref="#QuoteIcon-linear-gradient-2"
        />
        <linearGradient
          id="QuoteIcon-linear-gradient-7"
          x1="213.89"
          y1="131.67"
          x2="64.69"
          y2="131.67"
          xlinkHref="#QuoteIcon-linear-gradient-2"
        />
        <linearGradient
          id="QuoteIcon-linear-gradient-9"
          x1="-4.92"
          y1="-7.5"
          x2="28.1"
          y2="26.44"
          xlinkHref="#QuoteIcon-linear-gradient-2"
        />
        <linearGradient
          id="QuoteIcon-linear-gradient-10"
          x1="80.83"
          y1="98.61"
          x2="181.29"
          y2="98.61"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#0f5c52" />
          <stop offset="1" stopColor="#0a423b" />
        </linearGradient>
      </defs>
      <g>
        <path
          className="cls-1"
          d="M105,7.7V143.35a7.7,7.7,0,0,1-7.7,7.7H7.7a7.7,7.7,0,0,1-7.7-7.7V26.14L30.18,0H97.26A7.7,7.7,0,0,1,105,7.7Z"
        />
        <rect
          className="cls-2"
          x="16.98"
          y="33.17"
          width="71.01"
          height="4.95"
          rx="1.13"
        />
        <rect
          className="cls-3"
          x="16.98"
          y="45.49"
          width="71.01"
          height="4.95"
          rx="1.13"
        />
        <rect
          className="cls-4"
          x="16.98"
          y="87.35"
          width="71.01"
          height="4.95"
          rx="1.13"
        />
        <rect
          className="cls-5"
          x="16.98"
          y="99.66"
          width="40.64"
          height="4.95"
          rx="1.13"
        />
        <rect
          className="cls-6"
          x="16.98"
          y="57.81"
          width="54.23"
          height="4.95"
          rx="1.13"
        />
        <rect
          className="cls-7"
          x="16.98"
          y="129.2"
          width="19.78"
          height="4.95"
          rx="1.13"
        />
        <rect
          className="cls-7"
          x="68.21"
          y="129.2"
          width="19.78"
          height="4.95"
          rx="1.13"
        />
        <path className="cls-8" d="M30.18,0V18.44a7.7,7.7,0,0,1-7.7,7.7H0Z" />
        <circle className="cls-9" cx="100.99" cy="98.61" r="20.86" />
        <path
          id="Icon_awesome-check"
          data-name="Icon awesome-check"
          className="cls-10"
          d="M97.18,107.11,89.47,99.4a1.2,1.2,0,0,1,0-1.68h0L91.15,96a1.18,1.18,0,0,1,1.68,0h0L98,101.23,109.15,90.1a1.2,1.2,0,0,1,1.68,0h0l1.67,1.69a1.17,1.17,0,0,1,0,1.67h0L98.86,107.11a1.2,1.2,0,0,1-1.68,0Z"
        />
      </g>
    </svg>
  );
};

export default QuoteIcon;
